
import { BRAND } from '@/config'
import { computed, defineComponent, h } from 'vue'
import { useI18n } from 'vue-i18n'

const brand = BRAND.MAIN.name

export default defineComponent({
  name: 'Translate',
  props: {
    path: String,
    args: {
      type: Object,
      default: () => ({}),
    },
    as: {
      type: String,
      default: 'span',
    },
    custom: {
      type: [Boolean, String],
      default: false,
    },
    multiline: Boolean,
    eof: {
      type: String,
      default: ';',
    },
    useBrand: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, ctx) {
    const dft = ctx.slots.default || (() => props.path)
    const { t, te, locale } = useI18n()

    const replace: string[] = []

    const translated = computed(() => {
      let path = props.path

      if (props.useBrand && te(`${props.path}_${brand}`, locale.value)) {
        path = `${props.path}_${brand}`
      }

      if (!path || !te(path, locale.value)) return ''

      let tArgs = props.args

      if (typeof props.custom === 'string') {
        const params = props.custom.split(',')
        const args: { [key in string]: string } = {}
        params.forEach((item: string) => {
          const val = '$' + item.trim() + '$'
          args[item.trim()] = val
          replace.push(val)
        })

        tArgs = args
      }

      const text = t(path, {
        ...tArgs,
        brand,
      })

      if (props.multiline) {
        return text.split(props.eof)
      }

      return text
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const components: any[] = []

    if (typeof props.custom === 'string') {
      let td = translated.value as string
      if (!td) {
        return components.push((ctx.slots.default || (() => props.path))?.())
      }
      for (let i = 0; i < replace.length; i++) {
        const temp = td.split(replace[i])
        td = temp[1]
        components.push(temp[0])
        components.push(ctx.slots[replace[i].replace(/\$/g, '')]?.({ t }))
        if (i === replace.length - 1) {
          components.push(temp[1])
        }
      }
    }

    // 测试args结合custom 传slot 测试用
    // if (props.path === 'home_59') {}
    // slot变量参照 plstats_19

    return () => props.custom
      ? (typeof props.custom === 'string' ? h(props.as, components) : dft({
        t,
        td: translated.value,
      }))
      : h(props.as, [translated.value || dft()])
  },
})
