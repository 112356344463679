/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/4/9
 * @description
 *   i18n.ts of WeTrade
 */
import * as R from 'ramda'
import { createI18n } from 'vue-i18n'
import enMessages from '@/locales/en.json'

export const SUPPORTED_LANGS = ['en', 'vi', 'hi', 'ja', 'zh-TW', 'ph', 'th', 'de', 'fr', 'es', 'pt', 'it', 'id', 'tc', 'ma', 'pl', 'nl']

export const languages = R.zipWith(
  (lang, label) => ({
    lang,
    flag: lang,
    label,
  }),
  SUPPORTED_LANGS,
  [
    'English', 'Tiếng Việt', 'हिन्दी', '日本', '繁體中文', 'Pilipino', 'ไทย', 'Deutsch', 'Français',
    'Español', 'Português', 'Italiano', 'bahasa Indonesia', 'Türkçe', 'Malaysia', 'Polski', 'Nederlands',
  ],
)

export const DEFAULT_LANG = SUPPORTED_LANGS[0]

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: DEFAULT_LANG,
  messages: {
    en: enMessages,
  },
})

export const i18nInstance = i18n

export const translate = i18n.global.t

export default i18n.global
