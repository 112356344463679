/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/26
 * @description
 *   config.ts of WeTrade
 */
import * as R from 'ramda'
import { localGet } from 'essential/store/localStore'
import { getQueryParam } from 'essential/util'
import { DEFAULT_LANG, SUPPORTED_LANGS } from '@/i18n'

export const BRAND = {
  MAIN: {
    value: '1',
    name: 'WeTrade',
  },
}

const DEVICES = {
  OTHER: 0,
  ANDROID: 1,
  IOS: 2,
  H5: 4,
}

export const keymap = {
  user: {
    accountNum: 'f0',
    avatar: 'bi',
    countryCode: 'l9',
    faceBookBindStatus: 'i2',
    fbId: 'jt',
    invitationCode: 'j4',
    mobile: 'a3',
    mobileBindStatus: 'h9',
    nickName: 'ea',
    token: 'cj',
    userId: 'd2',
    email: 'e1',
    islamFlag: 'i62',
    googleBindStatus: 'gbs',
    googleEmail: 'ggm',
  },
  label: {
    uuid: 'u2',
    session: {
      dismissMissionCard: 'x0',
    },
    accountType: 'n5',
    watchList: 'i3',
    monitorList: 'z2',
    posts: 'o3',
    language: 's0',
    color: 'x9',
    resolution: 's2',
    studyConfig: 's3',
    theme: 'th',
    adid: 'jj',
  },
  search: {
    deviceType: 'd',
    appVersion: 'v',
    sdkVersion: 'av',
    imei: 'i',
    channel: 'c',
    deviceInfo: 'f',
    appId: 'ai',
    inviteUser: 'ii',
    pushMessage: 'p',
    updateVersion: 'uv',
    vestBagId: 'vbi',
    package: 'pkg',
  },
  guide: {
    marketList: 'li',
    position: 'k3',
    create: 'q9',
    switchAccount: 'l3',
    depositTip: 'dt',
    membershipHelp: 'mh',
    remindPlay: 'rp',
    fastGuideDialog: 'fg',
    copyGuideDialog: 'cg',
    quickPlayTips: 'qp',
    cryptoTrade: 'cp',
    specialTips: 'spt',
    liveGuide: 'lgd',
    liveLike: 'll',
  },
  cup: {
    mainTab: 'mt',
    subTab: 'st',
  },
  indicators: {
    data: 'ind',
  },
  recharge: {
    optimize: 'oz',
  },
  dailyBonus: {
    isShowToday: 'ist',
  },
}

export const market = {
  excode: 'EX',
}

export const request = {
  staticRequestData: {
    // static
    appId: getQueryParam(keymap.search.appId) || BRAND.MAIN.value,
    device: getQueryParam(keymap.search.deviceType) || DEVICES.ANDROID,
    version: getQueryParam(keymap.search.appVersion) || '0.0.0',
    market: getQueryParam(keymap.search.channel) || 'dev',
    updateVersion: getQueryParam(keymap.search.updateVersion) || '',
    vestBagId: getQueryParam(keymap.search.vestBagId) || 1,
    excode: market.excode,
    adId: 'dev',
  },

  get dynamicRequestData () {
    return {
      countryCode: localGet(keymap.user.countryCode),
      mobile: localGet(keymap.user.mobile), // mobile
      token: localGet(keymap.user.token), // token
      userId: localGet(keymap.user.userId),
      accountNum: localGet(keymap.user.accountNum),
      language: getLangIndex(),
    }
  },

  baseUrl: '/api/',
}

export const events = {
  login: 'login',
  logout: 'logout',
  tokenExpired: 'expired.token',
  accountChanged: 'change.account',
  postRefresh: 'refresh.post', // 更新帖子
  transactionUpdate: 'update.transaction',
  watchListUpdate: 'update.watchlist',
  fastTradeProductChange: 'change.product.fast', // 这里因为tab的props响应式有问题，暂时用event代替
  quickClose: 'close.quick',
  activated: 'activated',
  liveGiftMsg: 'liveGiftMsg',
  liveDepositAct: 'liveDepositAct',
  liveTradeAct: 'liveTradeAct',
  googleLogin: 'googleLogin',
  resolutionChange: 'resolutionChange',
  redraw: '__redraw', // 重绘图表
}

const isDevMode = () => process.env.NODE_ENV === 'development'

const isDevContext = () => isDevMode() || process.env.VUE_APP_SERVER_CONTEXT === 'development'

export const format = {
  moneyFactor: 10000,
}

export const flag = {
  get isIOS () {
    return !!window.navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  },
  get isDevMode () {
    return isDevMode()
    // return false
  },
  get isDevContext () {
    return isDevContext()
  },
  get isPre () {
    return location.host === 'pre.wetrade.one'
  },
  get isLite () {
    return getQueryParam(keymap.search.package) === 'lite'
  },
}

const servers = {
  dev: {
    // 接口
    api: 'http://wetrade-m-new.thebestdeveloperever.com',
    // 行情报价
    // api: 'https://m.wetrade.one',
    // quotation: 'ws://wetrade-quo-ws-new.thebestdeveloperever.com/ws',
    quotation: 'wss://quo-ws.wt-mob.com/ws',
    // 推送socket地址种子地址
    // pushSeed: 'https://static.wetrade.one/c.js',
    // pushSeed: 'http://fast-trade-test.oss-cn-hongkong.aliyuncs.com/wetrade-c.js',
    pushSeed: 'https://static.wetrade.one/c.js',
  },
  'pre.wetrade.one': {
    api: 'https://m.wetrade.one',
    quotation: 'wss://quo-ws.wetrade.one/ws',
    pushSeed: 'https://static.wetrade.one/c.js',
  },
  'app.wetrade.one': {
    api: 'https://m.wetrade.one',
    quotation: 'wss://quo-ws.wetrade.one/ws',
    pushSeed: 'https://static.wetrade.one/c.js',
  },
  'app.wt-mob.com': {
    api: 'https://m.wt-mob.com',
    quotation: 'wss://quo-ws.wt-mob.com/ws',
    pushSeed: 'https://static.wt-mob.com/wt-mob.js',
  },
  'app.we-trade.app': {
    api: 'https://m.we-trade.app',
    quotation: 'wss://quo-ws.we-trade.app/ws',
    pushSeed: 'https://static.we-trade.app/we-trade.app.js',
  },
}

const key = location.host as keyof typeof servers

export const server = {
  translate: 'https://m.wetrade.one/api/web/translate/jsonp',
  ...(isDevContext() ? servers.dev : (servers[key] ?? servers.dev)),
}

export const regExps = {
  topic: /#[^#\W]+/g,
  emoji: /:[^:]+:/g,
}

export const interval = {
  // settings
  ledRefreshInterval: 1000 * 3,
  accountRefreshInterval: 1000 * 2,
  postCheckInterval: 1000 * 5,
}

export default {
  keymap,
  market,
  request,
  events,
  server,
  format,
  flag,
  interval,
  regExps,
}

/**
 * 把language code转换为 index
 */
export const getLangIndex = () => Math.max(0, R.findIndex(
  R.equals(localGet(keymap.label.language || DEFAULT_LANG)), SUPPORTED_LANGS))
